<template lang="pug">
SwiperBlock(
  :slidesData="showableMulticolorThemes"
  :pagination="false"
  :slidesPerView="1.75"
  :slidesPerViewTabletLg="1.75"
  :slidesPerViewTabletSm="1.75"
)
  template(#heading)
    OmHeading.mb-6.font-size-3(h3) {{ $t('featuredThemes') }}
  template(#link)
    OmLink.text-nowrap(
      primary
      withIconRight
      data-track-property="change:moreThemes"
      @click="onSeeAll(allThemesLink)"
    ) {{ $t('seeAllThemes') }}
  template(#slide-component="theme")
    ThemeCard(
      :theme="theme"
      :key="theme.name"
      :name="theme.name"
      :to="getListingRoute(theme)"
      lg
      @navigateWithFilterReset="$emit('navigateWithFilterReset', $event)"
    )
</template>

<script>
  import { mapState } from 'vuex';
  import SwiperBlock from '@/components/TemplateChooser/components/blocks/SwiperBlock.vue';
  import ThemeCard from '@/components/TemplateChooser/components/cards/ThemeCard.vue';
  import { THEME_FAMILIES_NAMES_ORDERED } from '@om/template-properties/src/theme/constants';

  export default {
    components: { ThemeCard, SwiperBlock },
    computed: {
      ...mapState('templateChooser', {
        themes: 'themeFamilies',
      }),
      allThemesLink() {
        return {
          name: 'themes-collection',
        };
      },
      showableMulticolorThemes() {
        return this.themes
          .filter(({ multicolor, thumbnail }) => multicolor && thumbnail)
          .sort((a, b) => {
            const indexA = THEME_FAMILIES_NAMES_ORDERED.indexOf(a.name);
            const indexB = THEME_FAMILIES_NAMES_ORDERED.indexOf(b.name);
            return indexA - indexB;
          });
      },
    },
    methods: {
      onSeeAll(allThemesLink) {
        this.$router.push(allThemesLink);
      },
      getListingRoute(theme) {
        return {
          name: 'themes-templates',
          params: { slug: `${theme.name}-${theme._id}` },
        };
      },
    },
  };
</script>
<style lang="sass">
  @import '../../../../sass/variables/_colors'
  .theme-slider-body
    max-width: calc(100vw - 30rem)
    .swiper-button-next,
    .swiper-button-prev
      width: 2.75rem
      height: 2.75rem
      background: white
      border-radius: 100%
      box-shadow: 0 4px 16px rgba(0,0,0,0.1)
      &:after
        font-size: 14px
        color: $om-gray-700
    .swiper-button-prev
      left: -13px
    .swiper-button-next
      right: -13px
    .swiper-button-disabled
      display: none
    .template-chooser-home-wrapper
      overflow: hidden
    .swiper-pagination
      padding: 20px 0
      width: 100% !important
      left: auto
      right: auto
      margin: auto
    .swiper-pagination-bullet
      width: 12px
      height: 12px
      background: $om-gray-400
      opacity: 1
      transform: scale(1)
      margin: 0 5px
      left: auto !important
      &.swiper-pagination-bullet-active
        background: $om-orange
    .theme-slider-body-swiper
      width: 100%
      height: auto
</style>
